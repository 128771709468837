export const handler = (el, e) => {
  e.preventDefault();

  // Toggle the overlay
  const overlay = document.querySelector(".js-menu-overlay");
  const toggle = document.querySelector(".js-menu-overlay-toggle");

  // Set the data attribute 'data-menu-is-open' to true if the current value is false
  // and vice versa
  const isOpen = overlay.getAttribute("data-menu-is-open");

  // Find the menu overlay backdrop
  // const overlayBackdrop = document.querySelector(".js-menu-overlay-backdrop");

  // Set the data attribute 'data-menu-is-open' to true if the current value is false
  // and vice versa
  el.setAttribute("data-menu-is-open", !isOpen);
  overlay.setAttribute("data-menu-is-open", !isOpen);
  toggle.setAttribute("aria-expanded", !isOpen);
};
