// Import libraries and polyfills
import Promise from "promise-polyfill";
import "whatwg-fetch";
import "focus-visible";
import "classlist-polyfill";
import { onDomReady } from "@grrr/ready";
import { handle, enhance } from "@grrr/hansel";
import rafPolyfill from "./polyfills/request-animation-frame";

// Import functions that are executed on DOMready regardless of DOM
import cookieConsent from "./modules/cookie-consent";
import { enhancer as scrollListener } from "./modules/scroll-listener";
import { enhancer as responsive } from "./modules/responsive";
import { default as disableHoverStylesOnScroll } from "./modules/disable-hover-styles-on-scroll";

// Import handlers
import { handler as classToggler } from "./modules/class-toggler";
import { handler as cookieConsentShow } from "./modules/cookie-consent";
// import { handler as gtmEventHandler } from "./modules/gtm-event";
import { handler as menuOverlayHandler } from "./modules/menu-overlay";
import { handler as menuOverlayBackdropHandler } from "./modules/menu-overlay-backdrop";
import {
  close as lazyVideoEmbedClose,
  play as lazyVideoEmbedPlay,
} from "./modules/lazy-video-embed";

// Import enhancers
// import { enhancer as gtmEventEnhancer } from "./modules/gtm-event";
import { enhancer as headroomEnhancer } from "./modules/headroom";
import { enhancer as menuOverlayEnhancer } from "./modules/menu-overlay";
import { enhancer as imageAccordion } from "./modules/image-accordion";
import { enhancer as readIndicator } from "./modules/read-indicator";
import { enhancer as timeline } from "./modules/timeline";
import { enhancer as unblurImage } from "./modules/unblur-image";

const executeOnReady = () => {
  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;
  window.Promise = window.Promise || Promise;

  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change
  cookieConsent(); // Start the the cookie consent.
};

const main = () => {
  executeOnReady();
  handle(document.documentElement, {
    classToggler,
    cookieConsentShow,
    // gtmEventHandler,
    menuOverlayHandler,
    menuOverlayBackdropHandler,
    lazyVideoEmbedClose,
    lazyVideoEmbedPlay,
  });
  enhance(document.documentElement, {
    // gtmEventEnhancer,
    headroomEnhancer,
    menuOverlayEnhancer,
    imageAccordion,
    readIndicator,
    timeline,
    unblurImage,
  });
};

onDomReady(main);
