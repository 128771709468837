import Headroom from "headroom.js";

export const enhancer = (el) => {
  // const navHeight = el.offsetHeight;
  const headroom = new Headroom(el, {
    // offset: navHeight,
    tolerance: {
      down: 0,
      up: 10,
    },
    classes: {
      initial: "headroom",
      pinned: "is-pinned",
      unpinned: "is-unpinned",
      top: "is-at-top",
      notTop: "is-not-at-top",
      notBottom: "is-not-at-bottom",
      bottom: "is-at-bottom",
    },
  });

  headroom.init();
};
