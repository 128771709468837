import { matchesBreakpoint } from "./responsive";

const trapFocus = (el) => {
  const focusableEls = el.querySelectorAll(
    "a[href]:not([disabled]), button:not([disabled])"
  );
  const firstFocusableEl = focusableEls[0];
  const lastFocusableEl = focusableEls[focusableEls.length - 1];
  firstFocusableEl.focus();

  el.addEventListener("keydown", (e) => {
    const isTabPressed = e.key === "Tab" || e.keyCode === 9;
    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) {
      /* shift + tab */
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }
    } else if (document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus();
      e.preventDefault();
    }
  });
};

export const handler = (el, e) => {
  e.preventDefault();

  // Set aria-expanded attribute to true if the current value is false
  const expanded = el.getAttribute("aria-expanded") === "true" || false;
  el.setAttribute("aria-expanded", !expanded);

  document.body.classList.toggle("menu-overlay-is-open", !expanded);

  // Toggle the overlay
  const overlay = document.querySelector(".js-menu-overlay");

  // Set the data attribute 'data-menu-is-open' to true if the current value is false
  const isOpen = overlay.getAttribute("data-menu-is-open") === "true" || false;
  overlay.setAttribute("data-menu-is-open", !isOpen);

  // Find the menu overlay toggle within the overlay
  const overlayToggles = document.querySelectorAll(".js-menu-overlay-toggle");

  // Map through overlayToggles
  overlayToggles.forEach((overlayToggle) => {
    overlayToggle.setAttribute("aria-expanded", !expanded);
  });

  // Focus on the first toggle
  if (overlayToggles[0].getAttribute("aria-expanded") === "false") {
    overlayToggles[0].focus();
  }

  // Find the menu overlay backdrop
  const overlayBackdrop = document.querySelector(".js-menu-overlay-backdrop");

  // Set the data attribute 'data-menu-is-open' to true if the current value is false
  overlayBackdrop.setAttribute("data-menu-is-open", !isOpen);

  trapFocus(overlay);
};

const toggleMenuStates = (el, overlayToggles) => {
  const overlayBackdrop = document.querySelector(".js-menu-overlay-backdrop");

  document.body.classList.remove("menu-overlay-is-open");

  el.setAttribute("data-menu-is-open", "false");
  overlayBackdrop.setAttribute("data-menu-is-open", "false");

  // Map through overlayToggles
  overlayToggles.forEach((overlayToggle) => {
    overlayToggle.setAttribute("aria-expanded", "false");
  });

  const firstToggle = overlayToggles[0];
  firstToggle.focus();
};

const hideOverlayOnEsc = (el, overlayToggles) => {
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      toggleMenuStates(el, overlayToggles);
    }
  });
};

const hideOverlayOnResize = (el, overlayToggles) => {
  window.addEventListener("resize", (e) => {
    if (matchesBreakpoint("medium")) {
      toggleMenuStates(el, overlayToggles);
    }
  });
};

export const enhancer = (el) => {
  const overlayToggles = document.querySelectorAll(".js-menu-overlay-toggle");
  hideOverlayOnEsc(el, overlayToggles);
  hideOverlayOnResize(el, overlayToggles);
};
