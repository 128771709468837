/* eslint-disable function-paren-newline */

import { map } from "@grrr/utils";

const siblings = (el) =>
  Array.from(el.parentNode.children).filter((child) => child !== el);

const togglePanelExpansion = (el) => {
  const panels = [...el.querySelectorAll(".js-image-accordion-panel")];

  map((panel) => {
    const button = panel.querySelector(".js-image-accordion-button");

    // Set custom property --image-accordion-panel-amount based on panelAmount
    panel.style.setProperty("--image-accordion-panel-amount", panels.length);

    button.addEventListener("click", (e) => {
      siblings(panel).map((sibling) =>
        sibling.setAttribute("aria-selected", false)
      );

      panel.setAttribute(
        "aria-selected",
        panel.getAttribute("aria-selected") === "false"
      );
    });
  }, panels);
};

export const enhancer = (el) => {
  togglePanelExpansion(el);
};
