export const enhancer = (element) => {
  const image = element.querySelector("img");

  if (!image) {
    return;
  }

  // Create an Intersection Observer
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      // Check if the target element is in view
      if (entry.isIntersecting) {
        // Add the 'in-view' class when in view
        element.classList.add("in-view");
      } else {
        // Do nothing when not in view (optional)
      }
    });
  });

  // Start observing the target element
  observer.observe(image);
};
