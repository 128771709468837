import { register as registerScrollListener } from "./scroll-listener";

export const enhancer = (element) => {
  const pageLength = document.body.scrollHeight - window.innerHeight;

  registerScrollListener("readIndicatorListener", () => {
    // translate length to percentage
    const percentage = (window.pageYOffset / pageLength) * 100;

    // initial starting point to make the bar visible
    if (percentage > 5) {
      element.value = percentage;
    }
    element.setAttribute("data-is-hidden", percentage > 95);
  });
};
